import { Edition, Property } from 'src/app/models/dwrm/base';
import { Upload } from 'src/app/models/dwrm/upload';
import { NotificationOriginName } from 'src/app/models/notification';
import { BaseModel, PaginatedResponse } from 'src/app/models/utils';

export const DOCUMENT_TYPES = [
  'DE_NT',
  'WE_NT',
  'SU_NT',
  'AN_RS',
  'INV',
] as const;

export enum DocumentTypes {
  DE_NT = 'Lieferschein',
  WE_NT = 'Wiegeschein',
  SU_NT = 'Begleitschein',
  AN_RS = 'Analyseblatt',
  INV = 'Rechnung',
}

export type DocumentType = keyof typeof DocumentTypes;

export interface DocumentData {
  id?: string;
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  orderId: string;
  uploadId?: string;
  type: string;
  number: string;
  properties: Property[];
  uploadedByDisposer?: boolean;
  payload?: {
    origin: NotificationOriginName;
    [key: string]: any;
  };
}

export interface Document extends BaseModel {
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  orderId: string;
  type: DocumentType;
  number: string;
  uploadIds: string[];
  uploads: Upload[];
  properties: Property[];
  editions: Edition[];
  origin: string;
}

export interface DocumentRequest extends BaseModel {
  messageId: string;
  producerCompanyId: string;
  disposerCompanyId: string;
  senderEmail: string;
  recipientEmail: string;
  isFinished: boolean;
  uploadId: string;
  upload?: Upload;
  documentIds: string[];
  documents?: Document[];
}

export type PaginatedDocuments = PaginatedResponse<Document[]>;

export interface MappedDocument extends Document {
  data: any;
}

export interface DocumentV2 extends BaseModel {
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  contractId?: string;
  orderIds: string[];
  uploadIds?: string[];
  uploads?: Upload[];
  properties: Partial<DocumentProperties>;
  positions: DocumentPosition[];
  editions?: Edition[];
  type: DocumentType;
  number: string;
  origin: string;
}

export type PaginatedDocumentsV2 = PaginatedResponse<DocumentV2[]>;

export interface DocumentDataV2 {
  id?: string;
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  orderIds: string[];
  uploadId?: string[];
  type: string;
  number: string;
  properties: Partial<DocumentProperties>;
  positions: DocumentPosition[];
  uploadedByDisposer?: boolean;
  updatedByAdmin?: boolean;
  payload?: {
    origin: NotificationOriginName;
    [key: string]: any;
  };
}

export interface DocumentProperties {
  // Entity related
  contractId: string;
  documentRequestId: string;

  // Common
  date: string;
  treatmentMethod: string;
  pageFrom: number;
  pageTo: number;

  // Weight notes
  weight: number;

  // Invoice
  totalCosts: number; // actual: price
  treatmentCosts: number;
  logisticCosts: number;
  containersCosts: number;
  otherCosts: number;

  // Supply note
  pickupDate: string;
  handoverDate: string;
  transferrer: string;
  transferee: string;
  transports: Transport[];

  // Analysis Result
  providedBy: string;
  laboratoryName: string;
  laboratoryAddress: string;
  laboratoryGln: string;
  [any: string]: any;
}

interface Transport {
  key: string;
  name: string;
  address: string;
  gln: string;
  type: string;
}

export interface DocumentPosition {
  orderId: string;
  properties: Partial<DocumentProperties>;
}

/* Document V2 Example */
const example: DocumentV2 = {
  id: '67cf16134e07b253e329d29c',
  created: '2025-03-10T17:40:51.578+0100',
  createdBy: 'c09a38c7-f361-4ae1-9f3e-78e751a477c5',
  updated: null,
  updatedBy: null,
  deleted: null,
  deletedBy: null,
  version: 0,
  userId: 'c09a38c7-f361-4ae1-9f3e-78e751a477c5',
  companyId: '01ef21aa-60aa-1cf0-8695-cc5c917bae10',
  disposerUserId: null,
  disposerCompanyId: '01ef21ab-378e-1c64-b5ef-b3ecedf55029',
  orderIds: ['674efd94da09ac4f46e46a24'],
  uploadIds: ['67cf16134e07b253e329d29a'],
  uploads: [],
  properties: {
    date: '2025-03-10T16:40:32.504Z',
    totalCosts: -1370,
  },
  positions: [
    {
      orderId: '674efd94da09ac4f46e46a24',
      properties: {
        weight: 123,
      },
    },
    {
      orderId: '67cacda0d81ca36b8546e7e5',
      properties: {
        weight: 321,
      },
    },
  ],
  editions: [],
  type: 'INV',
  number: 'RE02480',
  origin: 'USER',
};

/* SCHEMAS */

/* INVOICE
  data1: "Rechnungsdatum"
  data2: "Total EUR"
*/

/* DELIVERY_NOTE
  data1: "Lieferdatum"
*/

/* WEIGHT_NOTE
  data1: "Wiegescheinsdatum"
  data2: "Gesamtabfallmenge"
*/

/* SUPPLY_NOTE
  data1: "Abholdatum"
  data2: "Übergabedatum"
  data3: "Übergeber"
  data4: "Übernehmer"
  data5: "Gesamtabfallmenge"
  data_N: "Transporteuer_N"
*/

/* ANALYSTIC_RESULT
  data1: "Analysedatum"
  data2: "Bereitgestellt durch"
  data3: "Laborname"
  data4: "Laborstandort"
  data5: "GLN"
*/
